.feedback-card.confirm .confirm-title {
  text-align: left;
}

.feedback-card.confirm .text {
  color: #333333;
  font-family: SourceSansPro;
}

.feedback-card.confirm .submit-container {
  text-align: right;
  margin-top: 20px;
}

.feedback-card.confirm .submit-container .submit {
  margin-left: 10px;
}

.feedback-card.confirm .submit-container .submit.empty1 {
  border: 1px solid #94a8c2;
  color: #94a8c2;
}

.feedback-card.confirm .submit-container .submit.empty1:hover {
  background-color: #94a8c2;
  color: #ffffff;
}

.feedback-card.confirm .submit-container .submit.empty1:focus {
  background-color: #94a8c2 !important;
  color: #ffffff;
  outline: none !important;
  -webkit-box-shadow: 0px 0px 0px 3px rgb(182, 200, 221) !important;
  -moz-box-shadow: 0px 0px 0px 3px rgb(182, 200, 221) !important;
  box-shadow: 0px 0px 0px 3px rgb(182, 200, 221) !important;
}

.feedback-card.confirm .submit-container .submit.empty1:active {
  background-color: #94a8c2 !important;
  color: #ffffff;
  border-color: #94a8c2 !important;
  outline: none !important;
  -webkit-box-shadow: 0px 0px 0px 3px rgb(182, 200, 221) !important;
  -moz-box-shadow: 0px 0px 0px 3px rgb(182, 200, 221) !important;
  box-shadow: 0px 0px 0px 3px rgb(182, 200, 221) !important;
}
